import React from 'react'
import Layout from "../../../components/Layout";
import CaseStudyHeader from "../../../components/CaseStudyHeader";
import Cta from "../../../components/Cta";
import ContactBg from "../../../images/working-together-2.png"

import body from './index.md'

import HollowKnight from "../../../images/hollow-knight-ipad.png"

const ReactMarkdown = require('react-markdown')

function HollowKnightCaseStudy() {
  return (
    <Layout animated={true} >

      <div className="case-study-page-header-container">
        <div className="case-study-page-header-title">Case Study</div>
      </div>

      <CaseStudyHeader 
        reverse={false}
        type={'tablet'}
        color={'#fafafa'}
        textColor={'dark'}
        title={'Hollow Knight Website'}
        image={HollowKnight}
        tags={['Indie Game', 'Global Audience', 'Digital Product Sales']}
      />

    <div className="case-studies-content-container">
      <ReactMarkdown source={body} />
    </div>

    <Cta title="interested in working with us?" buttonText="contact" link="/contact" bg={ContactBg}/>
  </Layout>


  )
}

export default HollowKnightCaseStudy;
